import { Link } from "gatsby";
import React from "react";

import iconPhone from "../../static/images/icons/phone.svg";
import logo from "../../static/images/logo.png";

import Contacts from "../constants/contacts";

interface IHeaderProps {
	siteTitle?: string;
}

const scrollToElement = (name: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
	// Если мы не на главной, то переход без прокрутки
	if (document.location.pathname !== "/") {
		return;
	}
	// Прокрутка до элемента
	const element = document.querySelector(`a[id=${name}]`);
	if (element) {
		element.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	}
	// Добавляем в урл хеш
	window.history.pushState(null, "", `#${name}`);
	e.preventDefault();
};

const Header = (props: IHeaderProps) => (
	<header className="container header">
		<Link to="/" title="Стань чемпионом!" className="header__logo">
			<img alt="Стань чемпионом!" src={logo} />
		</Link>
		<ul className="header__menu">
			<li>
				<Link to="/#installation" onClick={(e) => scrollToElement("installation", e as any)}>
					Как установить
				</Link>
			</li>
			<li>
				<Link to="/#contacts" onClick={(e) => scrollToElement("contacts", e as any)}>
					Обратная связь
				</Link>
			</li>
		</ul>
		<a href={Contacts.phoneLink} className="phone header__phone">
			<img alt="" src={iconPhone} className="phone__icon" />
			<span className="visible-md">{Contacts.phone}</span>
		</a>
	</header>
);

export default Header;
