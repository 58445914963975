export const Contacts = {
	phone: "8 800 505 75 00",
	phoneLink: "tel:88005057500",
	email: "support@atlet.app",
	playMarket: "https://play.google.com/store/apps/details?id=app.atlet",
	appStore: "https://apps.apple.com/us/app/%D0%B0%D1%82%D0%BB%D0%B5%D1%82/id1489322630?l=ru&ls=1",
	serviceLink: "https://formspree.io/xkngnzve"
};

export default Contacts;
