import { Link } from "gatsby";
import React from "react";

import buttomUp from "../../static/images/button_up.png";
import iconEmail from "../../static/images/icons/email.svg";
import iconPhoneWhite from "../../static/images/icons/phone_white.svg";

import Contacts from "../constants/contacts";

const year = new Date().getFullYear();

const scrollToUp = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
	document.getElementsByTagName("body")[0].scrollIntoView({
		behavior: "smooth",
		block: "start"
	});
	e.preventDefault();
};

const Footer = () => (
	<footer className="footer">
		<div className="container">
			<div className="footer__left">
				<i className="icon icon--sch-logo" />
				<p className="footer__title">
					© {year}, <span>Стань чемпионом!</span>
				</p>
			</div>
			<div className="footer__center">
				<p className="footer__title">e-mail:</p>
				<a href={`mailto:${Contacts.email}`} className="email">
					<img alt="" src={iconEmail} className="email__icon" />
					<span>{Contacts.email}</span>
				</a>
			</div>
			<div className="footer__right">
				<p className="footer__title">телефон:</p>
				<a href={Contacts.phoneLink} className="phone">
					<img alt="" src={iconPhoneWhite} className="phone__icon" />
					<span>{Contacts.phone}</span>
				</a>
			</div>
			<Link to="#" className="footer__btnup" onClick={scrollToUp as any}>
				<img alt="" src={buttomUp} />
			</Link>
		</div>
	</footer>
);

export default Footer;
