/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

interface ISEOProps {
	title: string;
	description?: string;
	lang?: string;
	meta?: Array<JSX.IntrinsicElements["meta"]>;
}

const query = graphql`
	query {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`;
/**
 * TODO useStaticQuery заменен на StaticQuery из-за того, что страница /link/ не работает на production (видимо из-за того, что там используется свой graphql)
 * Похожая проблема была в другой либе https://github.com/birkir/gatsby-source-prismic-graphql/issues/70, https://github.com/birkir/gatsby-source-prismic-graphql/issues/77
 */
function SEO({ description = "", lang = "en", meta = [], title }: ISEOProps) {
	return (
		<StaticQuery
			query={query}
			render={(data) => {
				const site = data.site;

				const metaDescription = description || site.siteMetadata.description;

				return (
					<Helmet
						htmlAttributes={{
							lang
						}}
						title={title}
						titleTemplate={`%s | ${site.siteMetadata.title}`}
						meta={[
							{
								name: `description`,
								content: metaDescription
							},
							{
								property: `og:title`,
								content: title
							},
							{
								property: `og:description`,
								content: metaDescription
							},
							{
								property: `og:type`,
								content: `website`
							},
							{
								name: `twitter:card`,
								content: `summary`
							},
							{
								name: `twitter:creator`,
								content: site.siteMetadata.author
							},
							{
								name: `twitter:title`,
								content: title
							},
							{
								name: `twitter:description`,
								content: metaDescription
							},
							{
								name: "apple-itunes-app",
								content: "app-id=1489322630"
							}
						].concat(meta as any)}
					/>
				);
			}}
		/>
	);
}

export default SEO;
