/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby";
import React, { ReactNode } from "react";
import "../../static/styles/main.scss";

import Modal from "react-modal";
import Footer from "./footer";
import Header from "./header";

Modal.setAppElement(`#___gatsby`);
interface ILayout {
	withMainTag?: boolean;
	children: ReactNode;
}

const Layout = ({ children, withMainTag = true }: ILayout) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={(data) => (
			<>
				<Header siteTitle={data.site.siteMetadata.title} />
				{withMainTag ? <main>{children}</main> : children}
				<Footer />
			</>
		)}
	/>
);

export default Layout;
